import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStore } from "../../../stores/helpers/useStore";
import { observer } from "mobx-react-lite";
import "./LoginPage.css";

const initialValues = {
	email: "",
	password: "",
};

const loginationSchema = Yup.object().shape({
	email: Yup.string()
		.trim()
		.required("Email is required")
		.email("Email must be valid"),
	password: Yup.string()
		.trim()
		.min(6, "Minimum 6 characters")
		.max(20, "Maximum 20 characters")
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
			"Should have at least one number and one letter"
		)
		.required("Password is required"),
});

const LoginPage = (props) => {
	const { authStore } = useStore();
	const { history } = props;

	const formik = useFormik({
		initialValues,
		validationSchema: loginationSchema,
		onSubmit: async (values, { setFieldError, setSubmitting }) => {
			setSubmitting(true);
			try {
				await authStore.login(values.email, values.password);
			} catch (err) {
				setSubmitting(false);
				if (err?.response?.status === 409) {
					setFieldError(
						err?.response?.data?.param,
						err?.response?.data?.message
					);
				} else if (err?.response?.status === 406) {
					setFieldError("email", "No user with such an email.");
				} else if (err?.response?.status === 403) {
					setFieldError("password", "Wrong credentials.");
				}
			}
		},
	});

	return (
		<div className="login-page">
			<div className="login-card">
				<h1>Login</h1>
				<p>Sign in to your account</p>
				<form onSubmit={formik.handleSubmit} noValidate>
					<div className="form-group">
						<label>Email</label>
						<input
							type="email"
							name="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							className={formik.touched.email && formik.errors.email ? "error" : ""}
						/>
						{formik.touched.email && formik.errors.email && (
							<span className="error-text">{formik.errors.email}</span>
						)}
					</div>
					<div className="form-group">
						<label>Password</label>
						<input
							type="password"
							name="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							className={formik.touched.password && formik.errors.password ? "error" : ""}
						/>
						{formik.touched.password && formik.errors.password && (
							<span className="error-text">{formik.errors.password}</span>
						)}
					</div>
					<button
						type="button"
						className="forgot-password"
						onClick={() => history.push("/pages/auth/forgot-password")}
					>
						Forgot password?
					</button>
					<button className="primary-button" type="submit" disabled={formik.isSubmitting}>
						{formik.isSubmitting ? "Please wait..." : "Login"}
					</button>
					<button
						type="button"
						className="secondary-button"
						onClick={() => history.push("/pages/auth/register")}
					>
						Register Now!
					</button>
				</form>
			</div>
		</div>
	);
};

export default observer(LoginPage);
