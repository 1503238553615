import { makeAutoObservable } from "mobx";

class GameSettings {
  playersLimit = 999;
  questionsPerRound = 999;
  halfPoints = false;
  showAnswers = false;
  showScores = false;
  helperTips = false;
  scoringInterval = 1;
  lockQuestion = false;
  lockQuestionMode = "last";
  constructor(gameSettings) {
    makeAutoObservable(this);

    this.update(gameSettings);
  }

  update = ({
    playersLimit,
    questionsPerRound,
    halfPoints,
    showAnswers,
    showScores,
    helperTips,
    scoringInterval,
    lockQuestion,
    lockQuestionMode,
  }) => {
    this.playersLimit = playersLimit ?? 999;
    this.questionsPerRound = questionsPerRound ?? 999;
    this.halfPoints = halfPoints ?? false;
    this.showAnswers = showAnswers ?? false;
    this.showScores = showScores ?? false;
    this.helperTips = helperTips ?? false;
    this.scoringInterval = scoringInterval ?? 1;
    this.lockQuestion = lockQuestion ?? false;
    this.lockQuestionMode = lockQuestionMode ?? "last";
  };
}

export default GameSettings;
