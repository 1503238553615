import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Switch,
	TextField,
	Typography,
	CircularProgress,
} from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";

import ToolTip from "../ToolTip";

const validationSchema = Yup.object().shape({
	switchRounds: Yup.boolean().required(),
	adjustByResponse: Yup.boolean().required(),
	showQuestions: Yup.boolean().required(),
	adjustByQuestion: Yup.boolean().required(),
	displayCorrectAnswer: Yup.boolean().required(),
	maxPointValue: Yup.number()
		.required("Max point value is required")
		.min(1, "Minimum 1!"),
	pauseModeContent: Yup.string().oneOf(
		["scoreTable", "gamePauseMessage"],
		"Wrong mode!"
	),
	gamePauseMessage: Yup.string().when("pauseModeContent", {
		is: (val) => val === "gamePauseMessage",
		then: Yup.string().required("Please add game pause message!"),
	}),
});

const useStyles = makeStyles((theme) => ({
	fieldName: {
		"& .MuiFormControlLabel-label": {
			width: 200,
			textAlign: "left",
		},
	},
	formControl: {
		width: "100%",
		marginLeft: 12,
		marginRight: 12,
	},
	checkbox: {
		"& .MuiFormControlLabel-label": {
			width: 204,
			textAlign: "left",
		},
	},
	pauseModeContent: {
		width: 200,
		textAlign: "left",
		margin: "10px 15px 10px 36px",
	},
	error: {
		color: "red",
		marginTop: ".85rem",
		textAlign: "left",
		maxWidth: 276,
		marginLeft: 11,
	},
}));

const ProGameOptions = ({
	proGameOptions: {
		switchRounds,
		adjustByResponse,
		showQuestions,
		adjustByQuestion,
		maxPointValue,
		pauseModeContent,
		gamePauseMessage,
		displayCorrectAnswer,
	},
	setOpenTipModal,
	setModalContent,
	updateProGameOptions,
}) => {
	const [updatingError, setUpdatingError] = useState("");
	const classes = useStyles();

	const formik = useFormik({
		initialValues: {
			switchRounds,
			adjustByResponse,
			showQuestions,
			adjustByQuestion,
			maxPointValue,
			pauseModeContent,
			gamePauseMessage,
			displayCorrectAnswer,
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			if (JSON.stringify(values) === JSON.stringify(formik.initialValues))
				return;

			try {
				await updateProGameOptions(values);
			} catch (err) {
				setUpdatingError(
					err.response.data?.message || "Saving failed, please try again!"
				);
			}
		},
	});

	useEffect(() => {
		setUpdatingError("");
	}, [formik.values]);

	const handleAdjustByRes = () => {
		setModalContent(
			<>
				Enabling this option will display additional plus/minus buttons beside
				each response during the marking phase. You can use them to award more
				or less points for all players with a given response.
			</>
		);
		setOpenTipModal(true);
	};

	const handleSwitchRounds = () => {
		setModalContent(<>Enable this option to switch rounds.</>);
		setOpenTipModal(true);
	};

	const handleAdjustByQuestion = () => {
		setModalContent(
			<>
				Enable this option to display a dropdown box during marking that will
				allow you to change the amount of points to award on the fly for each
				individual question.
			</>
		);
		setOpenTipModal(true);
	};

	const handleDisplayCorrectAnswer = () => {
		setModalContent(
			<>
				Enable this option to display all the correct answers to the player
				during the "marking" phase. This is only applicable to pre-entered and
				automatic modes.
			</>
		);
		setOpenTipModal(true);
	};

	const handleMaxPoint = () => {
		setModalContent(
			<>
				Set the max value available in the drop-down box for the previous
				setting.
			</>
		);
		setOpenTipModal(true);
	};

	const handlePauseModeContent = () => {
		setModalContent(
			<>
				If "Score Table" is selected players will see a table with all players
				and their scores.
				<br />
				Otherwise, players will see your custom message.
			</>
		);
		setOpenTipModal(true);
	};

	const handleShowQuestions = () => {
		setModalContent(
			<>
				If you are using pre-entered or automatic mode, this option allows
				players to see the questions on their own devices.
			</>
		);
		setOpenTipModal(true);
	};

	const proGameTip =
		"As an enterprise user, all of your hosts will have access to 'Advanced Options' (Previously 'Pro Options') these settings. They are only separated here for visible convenience";

	return (
		<Box display="flex" alignItems="start" flexDirection="column" p={2.5}>
			<strong variant="h4" component="h4">
				Advanced Options <ToolTip text={proGameTip} />
			</strong>
			<Box p={2} marginTop={2}>
				<FormGroup row>
					<Grid container direction={"column"} spacing={3}>
						<Box display="flex" alignItems="start">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="adjustByResponse"
										onChange={formik.handleChange}
										checked={formik.values.adjustByResponse}
									/>
								}
								label="Adjust score by Response"
							/>
							<IconButton onClick={handleAdjustByRes}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="start">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="showQuestions"
										onChange={formik.handleChange}
										checked={formik.values.showQuestions}
									/>
								}
								label="Show Questions to Player"
							/>
							<IconButton onClick={handleShowQuestions}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="start">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="switchRounds"
										onChange={formik.handleChange}
										checked={formik.values.switchRounds}
									/>
								}
								label="Switch Rounds"
							/>
							<IconButton onClick={handleSwitchRounds}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="start">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="displayCorrectAnswer"
										onChange={formik.handleChange}
										checked={formik.values.displayCorrectAnswer}
									/>
								}
								label="Display Correct Answers to Players"
							/>
							<IconButton onClick={handleDisplayCorrectAnswer}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="start">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="adjustByQuestion"
										onChange={formik.handleChange}
										checked={formik.values.adjustByQuestion}
									/>
								}
								label="Adjust score by Question"
							/>
							<IconButton onClick={handleAdjustByQuestion}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="start">
							<FormControl className={classes.formControl}>
								<TextField
									size="small"
									label="Max Point Value"
									name="maxPointValue"
									margin="dense"
									type="number"
									inputProps={{ min: 1, max: Number.MAX_SAFE_INTEGER }}
									value={formik.values.maxPointValue}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.touched.maxPointValue &&
										!!formik.errors.maxPointValue
									}
									helperText={
										formik.touched.maxPointValue && formik.errors.maxPointValue
									}
								/>
							</FormControl>
							<IconButton onClick={handleMaxPoint}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<hr />

						<Box display="flex" alignItems="start" className={classes.formControl}>
						<strong variant="h4" component="h4">
							Idle Display Option 
						</strong>
						</Box>

						<Box display="flex" alignItems="start" marginLeft={1.5}>
							
							<FormControlLabel
								className={classes.checkbox}
								control={
									<Checkbox
										onClick={() =>
											formik.setFieldValue("pauseModeContent", "scoreTable")
										}
										checked={formik.values.pauseModeContent === "scoreTable"}
									/>
								}
								label="Score Table"
							/>
						</Box>

						<Box display="flex" alignItems="start" marginLeft={1.5}>
							<FormControlLabel
								className={classes.checkbox}
								control={
									<Checkbox
										onClick={() =>
											formik.setFieldValue(
												"pauseModeContent",
												"gamePauseMessage"
											)
										}
										checked={
											formik.values.pauseModeContent === "gamePauseMessage"
										}
									/>
								}
								label="Paused Game Message"
							/>
						</Box>

						<Box display="flex" alignItems="start">
							<FormControl className={classes.formControl}>
								<TextField
									size="small"
									label="Message"
									name="gamePauseMessage"
									margin="dense"
									disabled={
										formik.values.pauseModeContent !== "gamePauseMessage"
									}
									value={formik.values.gamePauseMessage}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.touched.gamePauseMessage &&
										!!formik.errors.gamePauseMessage
									}
									helperText={
										formik.touched.gamePauseMessage &&
										formik.errors.gamePauseMessage
									}
								/>
							</FormControl>
						</Box>
					</Grid>
				</FormGroup>

				{updatingError && (
					<Typography className={classes.error} variant="body1">
						{updatingError}
					</Typography>
				)}
			</Box>
			<Box display="flex" justifyContent="center" m={2.5}>
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={formik.handleSubmit}
					disabled={
						formik.isSubmitting ||
						JSON.stringify(formik.values) ===
							JSON.stringify(formik.initialValues)
					}
				>
					{formik.isSubmitting ? (
						<>
							Please wait <CircularProgress color="inherit" size={16} />
						</>
					) : (
						<> Save</>
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default observer(ProGameOptions);
