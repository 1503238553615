import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	Icon,
	IconButton,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
	CircularProgress,
} from "@material-ui/core";

import { useFormik } from "formik";
import * as Yup from "yup";

import { observer } from "mobx-react-lite";

const validationSchema = Yup.object().shape({
	playersLimit: Yup.number().required("Players limit is required"),
	scoringInterval: Yup.number().required("Scoring interval is required"),
	questionsPerRound: Yup.number().required("Questions per round is required"),
	halfPoints: Yup.boolean().required(),
	showAnswers: Yup.boolean().required(),
	showScores: Yup.boolean().required(),
	helperTips: Yup.boolean().required(),
	lockQuestion: Yup.boolean().required(),
	lockQuestionMode: Yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
	fieldName: {
		"& .MuiFormControlLabel-label": {
			width: 200,
			textAlign: "left",
		},
	},
	formControl: {
		width: "100%",
		marginLeft: 11,
	
	},
	error: {
		color: "red",
		marginTop: ".85rem",
		textAlign: "left",
		maxWidth: 276,
		marginLeft: 11,
	},
}));

const GameSettings = ({
	gameSettings: {
		playersLimit,
		questionsPerRound,
		halfPoints,
		showAnswers,
		showScores,
		helperTips,
		scoringInterval,
		lockQuestion,
		lockQuestionMode,
	},
	setOpenTipModal,
	setModalContent,
	updateGameSettings,
}) => {
	const [playersLimitOptions, setPlayersLimitOptions] = useState([]);
	const [questionsPerRoundOptions, setQuestionsPerRoundOptions] = useState([]);
	const [updatingError, setUpdatingError] = useState("");

	const classes = useStyles();

	const formik = useFormik({
		initialValues: {
			playersLimit,
			questionsPerRound,
			halfPoints,
			showAnswers,
			showScores,
			helperTips,
			scoringInterval,
			lockQuestion,
			lockQuestionMode,
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			if (JSON.stringify(values) === JSON.stringify(formik.initialValues))
				return;

			try {
				await updateGameSettings(values);
			} catch (err) {
				setUpdatingError(
					err.response.data?.message || "Saving failed, please try again!"
				);
			}
		},
	});

	useEffect(() => {
		setUpdatingError("");
	}, [formik.values]);

	useEffect(() => {
		let playerslimitToSet = [];
		for (let i = 1; i <= 100; i++) {
			playerslimitToSet.push(
				<MenuItem key={i} value={i}>
					{i}
				</MenuItem>
			);
		}
		setPlayersLimitOptions(playerslimitToSet);
	}, []);

	useEffect(() => {
		let questionsPerRound = [];
		for (let i = 1; i <= 998; i++) {
			questionsPerRound.push(
				<MenuItem key={i} value={i}>
					{i}
				</MenuItem>
			);
		}
		setQuestionsPerRoundOptions(questionsPerRound);
	}, []);

	const handleScoringInterval = () => {
		setModalContent(
			"Want to give it more of a Jeopardy feel? Use 100 as the interval -- or what ever you see fit."
		);
		setOpenTipModal(true);
	};

	const handleHalfPoints = () => {
		setModalContent(
			<>
				<b>
					"I said 'the movie with that guy', isn't that worth a half point?"
				</b>
				<br />
				<br />
				Enable this option to add the half-point button during the marking
				phase. You can also use it as a 'bonus' to award a full + half point'
			</>
		);
		setOpenTipModal(true);
	};

	const handleHelperTips = () => {
		setModalContent(
			"Toggle off/on the 'hovering' helper messages you love so much."
		);
		setOpenTipModal(true);
	};

	const handleQuestionsPerRound = () => {
		setModalContent(
			"By default we like to let you go on forever and ever asking questions. But if you feel like it, cap it off.  This only applies to 'Classic' mode as using the 'Pre-entered' list of questions would just limit it to the amount of questions available in that round."
		);
		setOpenTipModal(true);
	};

	const handlePlayerLimit = () => {
		setModalContent(
			"Set the max number of teams/players that can sign up for your room. Please contact support if you need to increase this beyond 100 so we can make the adjustments manually."
		);
		setOpenTipModal(true);
	};

	const handleAnswerSheet = () => {
		setModalContent(
			"With this enabled, the players will see their own responses during the marking phase, and even see them being marked in real-time."
		);
		setOpenTipModal(true);
	};

	const handlePlayerScores = () => {
		setModalContent(
			"With this enabled, the players will see their scores in real-time."
		);
		setOpenTipModal(true);
	};
	return (
		<Box display="flex" alignItems="start" flexDirection="column" p={2.5}>
			<strong variant="h4" component="h4">
				Game Settings
			</strong>
			<Box p={2} marginTop={2}>
				<FormGroup row>
					<Grid container direction={"column"} spacing={3}>
						<Box display="flex" alignItems="center">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="halfPoints"
										onChange={formik.handleChange}
										checked={formik.values.halfPoints}
									/>
								}
								label="Enable Half points"
							/>
							<IconButton onClick={handleHalfPoints}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="center">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="showAnswers"
										onChange={formik.handleChange}
										checked={formik.values.showAnswers}
									/>
								}
								label="Show Answers Sheet"
							/>
							<IconButton onClick={handleAnswerSheet}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="center">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="showScores"
										onChange={formik.handleChange}
										checked={formik.values.showScores}
									/>
								}
								label="Show Player Score"
							/>
							<IconButton onClick={handlePlayerScores}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="center">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="helperTips"
										onChange={formik.handleChange}
										checked={formik.values.helperTips}
									/>
								}
								label="Enable Helper Tips"
							/>
							<IconButton onClick={handleHelperTips}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						
						<Box display="flex" alignItems="center">
							<FormControlLabel
								className={classes.fieldName}
								control={
									<Switch
										name="lockQuestion"
										onChange={formik.handleChange}
										checked={formik.values.lockQuestion}
									/>
								}
								label="Lock Players to Host"
							/>
						
						</Box>
						
						<Box display="flex" alignItems="center">
						<FormControl
							className={classes.formControl}
							error={!!formik.errors.lockQuestionMode} // Add error handling if needed
							>
							<InputLabel>Lock Question Mode</InputLabel>
							<Select
								className={classes.select}
								name="lockQuestionMode"
								value={formik.values.lockQuestionMode}
								onChange={formik.handleChange}

							>
								<MenuItem value="last">Current or Previous</MenuItem>
								<MenuItem value="current">Exact</MenuItem>
							</Select>
							</FormControl>
						</Box>

						<Box display="flex" alignItems="left" marginTop={4}>
							<FormControl className={classes.formControl}>
								<TextField
									size="small"
									label="Scoring Interval"
									name="scoringInterval"
									margin="dense"
									type="number"
									inputProps={{ min: 1 }}
									value={formik.values.scoringInterval}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										!!formik.touched.scoringInterval &&
										!!formik.errors.scoringInterval
									}
									helperText={
										formik.touched.scoringInterval &&
										formik.errors.scoringInterval
									}
								/>
							</FormControl>
							<IconButton onClick={handleScoringInterval}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box display="flex" alignItems="center">
							<FormControl
								className={classes.formControl}
								error={!!formik.errors.playersLimit}
							>
								<InputLabel>Players Limit</InputLabel>
								<Select
									className={classes.select}
									name="playersLimit"
									value={formik.values.playersLimit}
									onChange={formik.handleChange}
								>
									<MenuItem key={999} value={999}>
										Infinite
									</MenuItem>
									{playersLimitOptions}
								</Select>
							</FormControl>
							<IconButton onClick={handlePlayerLimit}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>

						<Box
							display="flex"
							alignItems="center"
							paddingBottom={2}
							marginTop={2}
						>
							<FormControl
								className={classes.formControl}
								error={!!formik.errors.questionsPerRound}
							>
								<InputLabel>Questions per round</InputLabel>
								<Select
									className={classes.select}
									name="questionsPerRound"
									value={formik.values.questionsPerRound}
									onChange={formik.handleChange}
								>
									<MenuItem key={999} value={999}>
										Infinite
									</MenuItem>
									{questionsPerRoundOptions}
								</Select>
							</FormControl>
							<IconButton onClick={handleQuestionsPerRound}>
								<Icon>help_outline</Icon>
							</IconButton>
						</Box>
					</Grid>
				</FormGroup>

				{updatingError && (
					<Typography className={classes.error} variant="body1">
						{updatingError}
					</Typography>
				)}
			</Box>

			<Box display="flex" justifyContent="center" m={2.5}>
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={formik.handleSubmit}
					disabled={
						formik.isSubmitting ||
						JSON.stringify(formik.values) ===
							JSON.stringify(formik.initialValues)
					}
				>
					{formik.isSubmitting ? (
						<>
							Please wait <CircularProgress color="inherit" size={16} />
						</>
					) : (
						<> Save</>
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default observer(GameSettings);
