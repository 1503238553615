import { get } from "mobx";
import ApiService from "../abstracts/ApiService";

class HostsService extends ApiService {
	async getListOfHosts() {
		const response = await this.protectedRequest({
			url: "/admin/hosts-list",
		});

		return response;
	}

	async getListOfUsers(all = true) {
		const response = await this.protectedRequest({
			//url: "/admin/users",
			url: "/admin/users/by-room?all=" + all,
		});

		return response;
	}

	async getListOfRounds() {
		const response = await this.protectedRequest({
			url: `/admin/rounds-history/`,
		});
		return response;
	}

	async getListOfUserScores(
		start_date = null,
		end_date = null
	) {
		const response = await this.protectedRequest({
			url: `/admin/user-scores?start_date=${start_date}&end_date=${end_date}`,
		});
		return response;
	}

	async getListOfGameResults(
		start_date = null,
		end_date = null
	) {
		const response = await this.protectedRequest({
			url: `/admin/game-results?start_date=${start_date}&end_date=${end_date}`,
		});
		return response;
	}

	async getRoundHistory(roomId) {
		const response = await this.protectedRequest({
			url: `/admin/rounds-history/${roomId}`,
		});
		return response;
	}

	async getGameResult(gameHistoryId) {
		const response = await this.protectedRequest({
			url: `/admin/game-results/${gameHistoryId}`,
		});
		return response;
	}

	async inviteExistingHost(email, password) {
		const response = await this.protectedRequest({
			url: "/admin/invite-host",
			method: "post",
			data: { email, password },
		});

		return response;
	}

	async createNewHost(roomCode, password) {
		const response = await this.protectedRequest({
			url: "/admin/create-host",
			method: "post",
			data: { roomCode, password },
		});

		return response;
	}

	async deletHost(hostId) {
		const response = await this.protectedRequest({
			url: "/admin/host",
			method: "delete",
			data: { hostId },
		});

		return response;
	}

	async getAdvancedHostSettings(hostId) {
		const response = await this.protectedRequest({
			url: `/admin/advenced-host-settings/${hostId}`,
		});

		return response;
	}

	async updateRoomCode(roomCode, hostId) {
		const response = await this.protectedRequest({
			url: "/admin/room-code",
			method: "put",
			data: {
				roomCode,
				hostId,
			},
		});

		return response;
	}

	async updatePassword(password, hostId) {
		const response = await this.protectedRequest({
			url: "/admin/host-password",
			method: "put",
			data: {
				password,
				hostId,
			},
		});

		return response;
	}

	async updateHostGameSettings({
		gameSettings: {
			playersLimit,
			questionsPerRound,
			halfPoints,
			showAnswers,
			showScores,
			helperTips,
			scoringInterval,
			lockQuestion,
			lockQuestionMode

		},
		hostId,
	}) {
		const response = await this.protectedRequest({
			url: "/admin/host-game-settings",
			method: "put",
			data: {
				playersLimit,
				questionsPerRound,
				halfPoints,
				showAnswers,
				showScores,
				helperTips,
				lockQuestion,
				lockQuestionMode,
				scoringInterval,
				hostId,
			},
		});

		return response;
	}

	async updateHostProGameOptions({
		proGameOptions: {
			switchRounds,
			adjustByResponse,
			showQuestions,
			adjustByQuestion,
			maxPointValue,
			pauseModeContent,
			gamePauseMessage,
			displayCorrectAnswer,
		},
		hostId,
	}) {
		const response = await this.protectedRequest({
			url: "/admin/host-pro-game-options",
			method: "put",
			data: {
				switchRounds,
				adjustByResponse,
				showQuestions,
				adjustByQuestion,
				maxPointValue,
				pauseModeContent,
				gamePauseMessage,
				hostId,
				displayCorrectAnswer,
			},
		});

		return response;
	}
}

const instance = new HostsService();

export default instance;
