import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStore } from "../../../stores/helpers/useStore";
import { observer } from "mobx-react-lite";
import "./RegisterPage.css";

const initialValues = {
	email: "",
	password: "",
	confirmPassword: "",
};

const registrationSchema = Yup.object().shape({
	email: Yup.string()
		.trim()
		.required("Email is required")
		.email("Email must be valid"),
	password: Yup.string()
		.trim()
		.min(6, "Minimum 6 characters")
		.max(20, "Maximum 20 characters")
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
			"Should have at least one number and one letter"
		)
		.required("Password is required"),
	confirmPassword: Yup.string()
		.trim()
		.required("Password confirmation is required")
		.oneOf([Yup.ref("password")], "Passwords must match"),
});

const RegisterPage = (props) => {
	const {
		authStore: { registerUser },
	} = useStore();
	const { history } = props;

	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: async (values, { setFieldError, setSubmitting }) => {
			setSubmitting(true);
			try {
				await registerUser(values.email, values.password);
			} catch (err) {
				setSubmitting(false);
				if (err?.response?.status === 409) {
					setFieldError(
						err?.response?.data?.param,
						err?.response?.data?.message
					);
				} else if (err?.response?.status === 500) {
					history.push("/pages/errors/error-500");
				}
			}
		},
	});

	return (
		<div className="register-page">
			<div className="register-container">
				<div className="register-card">
					<h1>Register</h1>
					<p>Create your account</p>
					<form onSubmit={formik.handleSubmit} noValidate>
						<div className="form-group">
							<label>Email</label>
							<input
								type="email"
								name="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className={formik.touched.email && formik.errors.email ? "error" : ""}
							/>
							{formik.touched.email && formik.errors.email && (
								<span className="error-text">{formik.errors.email}</span>
							)}
						</div>
						<div className="form-group">
							<label>Password</label>
							<input
								type="password"
								name="password"
								value={formik.values.password}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className={formik.touched.password && formik.errors.password ? "error" : ""}
							/>
							{formik.touched.password && formik.errors.password && (
								<span className="error-text">{formik.errors.password}</span>
							)}
						</div>
						<div className="form-group">
							<label>Confirm Password</label>
							<input
								type="password"
								name="confirmPassword"
								value={formik.values.confirmPassword}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "error" : ""}
							/>
							{formik.touched.confirmPassword && formik.errors.confirmPassword && (
								<span className="error-text">{formik.errors.confirmPassword}</span>
							)}
						</div>
						<button type="submit" disabled={formik.isSubmitting}>
							{formik.isSubmitting ? "Please wait..." : "Register"}
						</button>
						<button
							type="button"
							className="secondary-button"
							onClick={() => history.push("/pages/auth/login")}
						>
							Sign in
						</button>
					</form>
				</div>
				<div className="info-card">
					<p className="subtitle">Welcome To</p>
					<h2>TriviaRat Enterprise</h2>
					<strong>Grow Your Trivia Empire</strong>
					<p>Unlock the full potential of your trivia empire. Manage multiple host accounts and access more in-depth insights into your events. </p>
					<small> Note: you will need to register a new account separately from your TriviaRat.com host account.</small>
				</div>
			</div>
		</div>
	);
};

export default observer(RegisterPage);
